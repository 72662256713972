import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
class Logos extends Component
{
    render(){
        
        return ( 
   <>
             <div className="clients-logo">
        <div className="container">
            <div className="row">
                <div className="col-12 flex flex-wrap justify-content-center justify-content-lg-between align-items-center">
                    <div className="logo-wrap">
                        <img src="images/logo-1.png" alt=""/>
                    </div> 
                    <div className="logo-wrap">
                        <img src="images/logo-2.png" alt=""/>
                    </div> 

                    <div className="logo-wrap">
                        <img src="images/logo-3.png" alt=""/>
                    </div>

                    <div className="logo-wrap">
                        <img src="images/logo-4.png" alt=""/>
                    </div> 
                    <div className="logo-wrap">
                        <img src="images/logo-5.png" alt=""/>
                    </div>
                </div> 
            </div> 
        </div> 
    </div>
     

     </>    
        );
    }
}

export default Logos;