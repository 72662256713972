import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";

class Footer extends Component
{
    render(){
        
        return ( 
            <>
<footer className="site-footer">
        <div className="footer-widgets">
            <div className="container">
                <div className="row">
                    

                    <div className="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
                        <div className="foot-contact">
                            <h2>Contact Us</h2>

                            <ul>
                                <li><b>Email:</b> reliwand.pharm@gmail.com</li>
                                <li><b>Phone:</b> (+91) 6386830726</li>
                                <li><b>Address:</b> 2-A/3, Kundan Mansion, Asf Ali Road, Turkman Gate, New Delhi - 110002, India </li>
                            </ul>
                        </div> 
                    </div> 

                    <div className="col-12 col-md-6 col-lg-6 mt-5 mt-lg-0">
                        <div className="quick-links flex flex-wrap">
                            <h2 className="w-100">Locate us</h2>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.6163447754493!2d77.23346457503398!3d28.64125848368514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd26bfcbb2ff%3A0xb5e32d63fb1a230!2sTurkman%20Gate%2C%20Chandni%20Chowk%2C%20Delhi!5e0!3m2!1sen!2sin!4v1700817316112!5m2!1sen!2sin" width="100%" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div> 

                    <div className="col-12 col-md-6 col-lg-3 mt-5 mt-lg-0">
                        <div className="follow-us">
                            <h2>Follow Us</h2>

                            <ul className="follow-us flex flex-wrap align-items-center">
                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            </ul>
                        </div> 
                    </div> 
                </div> 
            </div> 
        </div>
        <div className="footer-bar">
            <div className="container">
                &copy; All Rights Reserved Reliwand Pharmaceuticals Pvt. Ltd.
            </div> 
        </div> 

        
    </footer>
    </>
    );
    }
}
    export default Footer;