import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
import Logos from "../Components/Logos";
class Contact extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Contact Us</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="#"><i className="fa fa-home"></i> Home</a></li>
                        <li><a href="#"><i className="fa fa-home"></i> Products</a></li>
                        <li>Synovisc</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    
    <div class="row justify-content-between">
             

            <div class="col-12 col-lg-6">
                <div class="contact-form">
                    <h3>Send us your query!</h3>

                    <form>
                        <input type="text" placeholder="Your Name"/>
                        <input type="email" placeholder="Your Email"/>
                        <input type="text" placeholder="Subject"/>
                        <textarea placeholder="Your Message" rows="4"/> 
                        <input type="submit" value="Send Message"/>
                    </form>
                </div> 
            </div> 

            <div class="col-12 col-lg-6">
                <div class="contact-info">
                    <h3>Contact Us</h3>

                    

                    <ul class="p-0 m-0">
                        <li><span>Location:</span>2-A/3, Kundan Mansion, Asf Ali Road, Turkman Gate, New Delhi - 110002, India</li>
                        <li><span>Email:</span><a href="#">reliwand.pharm@gmail.com</a></li>
                        <li><span>Phone:</span><a href="#">(+91) 6386830726</a></li>
                    </ul>
                </div> 
            </div> 
        </div>
        </div>
<Logos/>
     </>    
        );
    }
}

export default Contact;