import React,{Component} from "react";
import Logo  from '../logo.jpg';
import Header from '../Components/Header';
import Logos from "../Components/Logos";
import { NavLink, Link } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';

class Home extends Component
{
    
    render(){
        const scrollToTop = () => {
            window.scrollTo(0, 0)
        }
        return ( 
            <>
              <Header/>
       
            <div className="icon-boxes">
            <div className="container-fluid">
                <div className="flex flex-wrap align-items-stretch">
                    <div className="icon-box">
                        <div className="icon">
                            <span className="ti-cup"></span>
                        </div> 
    
                        <header className="entry-header">
                            <h2 className="entry-title">QUALITY </h2>
                        </header> 
    
                        <div className="entry-content">
                            <p>Quality first approach</p>
                        </div> 
    
                        <footer className="entry-footer read-more">
                        <NavLink to="/">read more<i className="fa fa-long-arrow-right"></i></NavLink>
                        </footer> 
                    </div> 
    
                    <div className="icon-box">
                        <div className="icon">
                            <span className="ti-settings"></span>
                        </div>
                        
    
                        <header className="entry-header">
                            <h2 className="entry-title">SERVICE </h2>
                        </header>
                        
    
                        <div className="entry-content">
                            <p>: Industry leading service standards</p>
                        </div>
                        
    
                        <footer className="entry-footer read-more">
                        <NavLink to="/">read more<i className="fa fa-long-arrow-right"></i></NavLink>
                        </footer>
                        
                    </div>
                    
    
                    <div className="icon-box">
                        <div className="icon">
                            <span className="ti-move"></span>
                        </div>
                        
    
                        <header className="entry-header">
                            <h2 className="entry-title">INTEGRITY </h2>
                        </header>
                        
    
                        <div className="entry-content">
                            <p>Doing the right thing</p>
                        </div>
                        
    
                        <footer className="entry-footer read-more">
                        <NavLink to="/">read more<i className="fa fa-long-arrow-right"></i></NavLink>
                        </footer>
                        
                    </div>
                    
    
                    <div className="icon-box">
                        <div className="icon">
                            <span className="ti-ruler-pencil"></span>
                        </div>
                        
    
                        <header className="entry-header">
                            <h2 className="entry-title">INNOVATION </h2>
                        </header>
                        
    
                        <div className="entry-content">
                            <p>By thinking differently</p>
                        </div>
                        
    
                        <footer className="entry-footer read-more">
                        <NavLink to="/">read more<i className="fa fa-long-arrow-right"></i></NavLink>
                        </footer>
                        
                    </div>
                    
                </div>
                
            </div>
            
        </div>
        <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">About <span className="txtred">Rel</span><span className="txtyellow">iw</span>and</h2>

                        <p align="jusify">We at Reliwand Pharmaceuticals Pvt Ltd are the gateway to a more energetic, healthy living. We were established on January 20, 2023, with the goal of giving everyone access to high-quality medications and nutraceuticals. Since then, we have grown to become one of India's top pharmaceutical enterprises. We have gained the trust of patients, medical experts, and partners globally because of our aim to safeguard and improve people's health and well-being everywhere. </p>
                    </header> 

                    <div className="entry-content ezuca-stats">
                    <NavLink to="/about" onClick={scrollToTop} className="btn mt-4 mt-sm-0">Read More</NavLink>
                    </div> 
                </div> 

                <div className="col-12 col-lg-6 flex align-content-center mt-5 mt-lg-0">
                    <div className="ezuca-video position-relative">
                         

                        <img src="images/video-screenshot.png" alt=""/>
                    </div> 
                </div> 
            </div> 
        </div>
    </section>
       <div className="featured-courses horizontal-column courses-wrap">
       <div className="container">
           <div className="row">
               <div className="col-12">
                   <header className="heading flex justify-content-between align-items-center">
                       <h2 className="entry-title"><span className="txtred">Vision</span> & <span className="txtgreen">Mission</span></h2>
                      
                    
                   </header> 
               </div> 

               <div className="col-12 col-lg-6">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Vision</h2>

                               <div className="entry-meta flex flex-wrap align-items-center">
                                   <div className="course-author greensmall">Your Path to Better Health</div>
 
                               </div> 
                               <p>At Reliwand Pharmaceuticals Pvt Ltd, we see a world in which everyone has access to high-quality medications and nutraceuticals, enhancing people's health and wellbeing everywhere. Our steadfast dedication is to spearhead innovation and tenacity within the pharmaceutical industry.</p>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 

               <div className="col-12 col-lg-6">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Mission</h2>

                               <div className="entry-meta flex flex-wrap align-items-center">
                                   <div className="course-author greensmall">Protecting Health, Inspiring Confidence</div>
 
                               </div> 
                               <p>Protecting and improving each person's health and wellbeing who puts their trust in our medications is our main goal. We utilize the entire ability of our team to develop novel drugs that transform the pharmaceutical sector, whether we are selling them domestically or abroad.</p>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 



               <div className="col-12 col-lg-12">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Objectives</h2>

                                
                               <p>Our objective is to establish ourselves as a premier pharmaceutical firm both in India and beyond. Some of the most commonly used medications in the world were found and manufactured by our scientific research and development teams.</p>
                               <p>Our company objectives are as follows:-</p>
                                    <ul class="p-0 m-0 green-ticked">
                                        <li>Overall superior planning and oversight.</li>
                                        <li>To establish systems that impact quality.</li>
                                        <li>To authorize the release of new products.</li>
                                        <li>To rectify non-conformance and to take action in response to quality complaints.</li>
                                        <li>To verify, audit, and accredit suppliers.</li>
                                        <li>Keeping an eye on the stability of all active goods as well as new ones.</li>
                                        <li>Ability to comprehend value from the perspective of the consumer and customer pleasure</li>
                                        <li>Process-related issues, waste reduction, and inventory management.</li>
                                    </ul>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 





           </div> 
       </div> 
   </div>

   <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Our <span className="txtred">Products</span></h2>
                        <p className="greensmall">Empowering Wellness, Every Day with Our Products.</p>
                        <p align="jusify">Reliwand Pharmaceuticals Pvt Ltd differentiates itself in the pharmaceutical industry for a number of products.</p>
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                        <img src="../images/product1.png" style={{width:'100%' }}/>
                        <h3>PTH Pride</h3>
                        <p>PTH PRIDE  Injection is a man-made version of parathyroid hormone used to treat men and postmenopausal women who have severe osteoporosis with a high risk of fractures. </p>
                        <div class="entry-content ezuca-stats">
                        <NavLink onClick={scrollToTop}  to="/pth-pride" className="btn mt-4 mt-sm-0" >Read More</NavLink>
                           </div>
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product2.png"  style={{width:'100%' }}/>
                        <h3>Synovisc</h3>
                        <p>Synovisc Injection is a medicine used in the treatment of osteoarthritis. It decreases the pain and swelling in the joints. It may be used alone or in combination with other medicines.</p>
                        <div class="entry-content ezuca-stats"><NavLink onClick={scrollToTop} to="/synovisc" className="btn mt-4 mt-sm-0" >Read More</NavLink></div>
                </div>
            </div> 
        </div>
    </section>

    <section className="testimonial-section">
      
   


        <div className="swiper-container testimonial-slider">
            <div className="swiper-wrapper">
                
            <Carousel controls={false} indicators={false}>
      <Carousel.Item>
      <div className="swiper-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 order-2 order-lg-1 flex align-items-center mt-5 mt-lg-0">
                                <figure className="user-avatar">
                                    <img src="images/user-1.jpg" alt=""/>
                                </figure> 
                            </div> 

                            <div className="col-12 col-lg-6 order-1 order-lg-2 content-wrap h-100">
                                <div className="entry-content">
                                    <p>Together as teachers, students and universities we can help make this education available for everyone.</p>
                                </div> 

                                <div className="entry-footer">
                                    <h3 className="testimonial-user">Russell Stephens - <span>University in UK</span></h3>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
      </Carousel.Item>
      <Carousel.Item>
      <div className="swiper-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 order-2 order-lg-1 flex align-items-center mt-5 mt-lg-0">
                                <figure className="user-avatar">
                                    <img src="images/user-2.jpg" alt=""/>
                                </figure> 
                            </div> 

                            <div className="col-12 col-lg-6 order-1 order-lg-2 content-wrap h-100">
                                <div className="entry-content">
                                    <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                </div> 

                                <div className="entry-footer">
                                    <h3 className="testimonial-user">Robert Stephens - <span>University in Oxford</span></h3>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
      </Carousel.Item>
      <Carousel.Item>
      <div className="swiper-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 flex order-2 order-lg-1 align-items-center mt-5 mt-lg-0">
                                <figure className="user-avatar">
                                    <img src="images/user-3.jpg" alt=""/>
                                </figure> 
                            </div> 

                            <div className="col-12 col-lg-6 order-1 order-lg-2 content-wrap h-100">
                                <div className="entry-content">
                                    <p>Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
                                </div> 

                                <div className="entry-footer">
                                    <h3 className="testimonial-user">James Stephens - <span>University in Cambridge</span></h3>
                                </div> 
                            </div> 
                        </div> 
                    </div> 
                </div> 
      </Carousel.Item>
    </Carousel>
               

               
            </div> 

            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 mt-5 mt-lg-0">
                        <div className="swiper-pagination position-relative flex justify-content-center align-items-center"></div>
                    </div>
                </div> 
            </div> 
        </div> 
    </section>

    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Why <span className="txtred">Choose Us?</span></h2>
                        <p className="greensmall">Quality, Service, Integrity, Innovation - The Reliwand Advantage</p>
                        <p align="jusify">Reliwand Pharmaceuticals Pvt Ltd differentiates itself in the pharmaceutical industry for a number of strong reasons:</p>
                    </header> 

                    <ul class="p-0 m-0 green-ticked">
                        <li><b>Quality First Approach</b><br/> 
The foundation of our business is our commitment to excellence. In order to guarantee the efficacy and purity of our pharmaceutical and nutraceutical products, we comply with global quality standards.
</li>
                        <li><b>Industry-Leading Service Standards</b><br/>
We recognize the value of prompt service. Our group is dedicated to fulfilling your demands and exceeding your expectations as soon as possible.
</li>
                        <li><b>Doing the Right Thing</b><br/>
Reliwand's main value is integrity. We are committed to always acting morally and maintaining integrity, dependability, and openness in all of our dealings.
</li>
                        <li><b>Innovation Through Different Thinking</b><br/>
Our DNA is full of innovation. We consistently push the limits of pharmaceutical R&D, producing ground-breaking goods that raise the bar for the sector.
</li>
                        </ul>
                </div> 

                <div className="col-12 col-lg-4 flex align-content-center mt-5 mt-lg-0">
                    <div className="ezuca-video position-relative">
                         

                        <img src="images/why.png" alt=""/>
                    </div> 
                </div> 
            </div> 
        </div>
    </section>

   <Logos/>
   </>
        );
    }
}


export default Home;