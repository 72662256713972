import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
import Logos from "../Components/Logos";
class About extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>ABOUT</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="#"><i className="fa fa-home"></i> Home</a></li>
                        <li>About</li>
                    </ul>
                </div> 
            </div> 
        </div> 

        <div className="row">
            <div className="col-12">
                <div className="about-heading">
                    <h2 className="entry-title">Welcome to Reliwand Pharmaceuticals Pvt Ltd</h2>

                    <p>India's top pharmaceutical enterprises</p>
                </div> 
            </div> 

            <div className="col-12 col-lg-12">
                <div className="about-stories">
                    <h3>About Us</h3>

                    <p>We at Reliwand Pharmaceuticals Pvt Ltd are the gateway to a more energetic, healthy living. We were established on January 20, 2023, with the goal of giving everyone access to high-quality medications and nutraceuticals. Since then, we have grown to become one of India's top pharmaceutical enterprises. We have gained the trust of patients, medical experts, and partners globally because of our aim to safeguard and improve people's health and well-being everywhere. </p>

                    <p>With our unwavering commitment to innovation, honesty, and excellence, we have positioned ourselves as leaders in the pharmaceutical sector. Selecting Reliwand is a decision that will lead to improved health and a more promising future.</p>

                   
                </div> 
            </div> 

             
        </div> 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-8 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Why <span className="txtred">Choose Us?</span></h2>
                        <p className="greensmall">Quality, Service, Integrity, Innovation - The Reliwand Advantage</p>
                        <p align="jusify">Reliwand Pharmaceuticals Pvt Ltd differentiates itself in the pharmaceutical industry for a number of strong reasons:</p>
                    </header> 

                    <ul class="p-0 m-0 green-ticked">
                        <li><b>Quality First Approach</b><br/> 
The foundation of our business is our commitment to excellence. In order to guarantee the efficacy and purity of our pharmaceutical and nutraceutical products, we comply with global quality standards.
</li>
                        <li><b>Industry-Leading Service Standards</b><br/>
We recognize the value of prompt service. Our group is dedicated to fulfilling your demands and exceeding your expectations as soon as possible.
</li>
                        <li><b>Doing the Right Thing</b><br/>
Reliwand's main value is integrity. We are committed to always acting morally and maintaining integrity, dependability, and openness in all of our dealings.
</li>
                        <li><b>Innovation Through Different Thinking</b><br/>
Our DNA is full of innovation. We consistently push the limits of pharmaceutical R&D, producing ground-breaking goods that raise the bar for the sector.
</li>
                        </ul>
                </div> 

                <div className="col-12 col-lg-4 flex align-content-center mt-5 mt-lg-0">
                    <div className="ezuca-video position-relative">
                         

                        <img src="images/why.png" alt=""/>
                    </div> 
                </div> 
            </div> 
        </div>
    </section>
    <div className="featured-courses horizontal-column courses-wrap">
       <div className="container">
           <div className="row">
               <div className="col-12">
                   <header className="heading flex justify-content-between align-items-center">
                       <h2 className="entry-title"><span className="txtred">Vision</span> & <span className="txtgreen">Mission</span></h2>
                      
                    
                   </header> 
               </div> 

               <div className="col-12 col-lg-6">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Vision</h2>

                               <div className="entry-meta flex flex-wrap align-items-center">
                                   <div className="course-author greensmall">Your Path to Better Health</div>
 
                               </div> 
                               <p>At Reliwand Pharmaceuticals Pvt Ltd, we see a world in which everyone has access to high-quality medications and nutraceuticals, enhancing people's health and wellbeing everywhere. Our steadfast dedication is to spearhead innovation and tenacity within the pharmaceutical industry.</p>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 

               <div className="col-12 col-lg-6">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Mission</h2>

                               <div className="entry-meta flex flex-wrap align-items-center">
                                   <div className="course-author greensmall">Protecting Health, Inspiring Confidence</div>
 
                               </div> 
                               <p>Protecting and improving each person's health and wellbeing who puts their trust in our medications is our main goal. We utilize the entire ability of our team to develop novel drugs that transform the pharmaceutical sector, whether we are selling them domestically or abroad.</p>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 



               <div className="col-12 col-lg-12">
                   <div className="course-content flex flex-wrap justify-content-between align-content-lg-stretch">
                        

                       <div className="course-content-wrap">
                           <header className="entry-header">
                       

                               <h2 className="entry-title">Objectives</h2>

                                
                               <p>Our objective is to establish ourselves as a premier pharmaceutical firm both in India and beyond. Some of the most commonly used medications in the world were found and manufactured by our scientific research and development teams.</p>
                               <p>Our company objectives are as follows:-</p>
                                    <ul class="p-0 m-0 green-ticked">
                                        <li>Overall superior planning and oversight.</li>
                                        <li>To establish systems that impact quality.</li>
                                        <li>To authorize the release of new products.</li>
                                        <li>To rectify non-conformance and to take action in response to quality complaints.</li>
                                        <li>To verify, audit, and accredit suppliers.</li>
                                        <li>Keeping an eye on the stability of all active goods as well as new ones.</li>
                                        <li>Ability to comprehend value from the perspective of the consumer and customer pleasure</li>
                                        <li>Process-related issues, waste reduction, and inventory management.</li>
                                    </ul>
                           </header> 
                             
                       </div> 
                   </div> 
               </div> 





           </div> 
       </div> 
   </div>
<Logos/>
     </>    
        );
    }
}

export default About;