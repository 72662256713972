
import './App.css';

import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Products from './Pages/Products';
import PTH from './Pages/PTH ';
import Synovisc from './Pages/Synovisc';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Router>
     
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route exact path='/about' element={<About/>} />
          <Route exact path='/products' element={<Products/>}/>
          <Route exact path='/pth-pride' element={<PTH/>}/>
          <Route exact path='/synovisc' element={<Synovisc/>}/>
          <Route exact path='/career' element={<Career/>}/>
          <Route exact path='/contact-us' element={<Contact/>}/>
        </Routes>
        <Footer/>
       </Router>
    </div>
  );
}

export default App;
