import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "./Menu";
class Header extends Component
{
    render(){
        
        return ( 
   
            <div className="hero-content">   
        <header className="site-header">
           
            <Menu/>
        </header>

        <div className="hero-content-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="hero-content-wrap flex flex-column justify-content-center align-items-start">
                            <header className="entry-header">
                                <h4>Welcome to Reliwand Pharmaceuticals Pvt Ltd</h4>
                                <h1>India's top pharmaceutical enterprises</h1>
                            </header> 

                            

                            <footer className="entry-footer read-more">
                                <a href="/about">read more</a>
                            </footer> 
                        </div> 
                    </div> 
                </div> 
            </div> 
        </div>
        </div>
         
        );
    }
}

export default Header;