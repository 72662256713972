
import React,{Component, useState} from "react";
import Logo from '../images/logo.png';
import { NavLink, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Menu()
{
    const [showMenu, setShowMenu] = useState(false);
   
        return (
            <>
            
            <div className="top-header-bar">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 d-none d-md-flex flex-wrap justify-content-center justify-content-lg-start mb-3 mb-lg-0">
                        <div className="header-bar-email d-flex align-items-center">
                            <i className="fa fa-envelope"></i><NavLink to="/">reliwand.pharm@gmail.com</NavLink >
                        </div> 

                        <div className="header-bar-text lg-flex align-items-center">
                            <p><i className="fa fa-phone"></i> (+91) 6386830726</p>
                        </div> 
                    </div> 

                    <div className="col-12 col-lg-6 d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center">
                        <div className="header-bar-search">
                            
                        </div> 

                        <div className="header-bar-menu">
                             
                        </div> 
                    </div> 
                </div> 
            </div> 
        </div>  
        <div className="nav-bar">
<div className="container">
    <div className="row">
        <div className="col-9 col-lg-3">
            <div className="site-branding">
            <NavLink to="/"><img src={Logo} /></NavLink>
            </div> 
        </div> 
        <div className="col-3 col-lg-9 flex justify-content-end align-content-center">
        <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About Us</NavLink>
            <NavDropdown title="Products" id="navbarScrollingDropdown">
            <NavLink to="/pth-pride">PTH Pride</NavLink>
              <NavDropdown.Divider />
              <NavLink to="/synovisc">Synovisc</NavLink>
             
               
            </NavDropdown>
            <NavLink to="/career">Career</NavLink>
            <NavLink to="/contact-us">Contact</NavLink>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
            
        </div> 
    </div> 
</div> 
</div> 
</> );
}

export default Menu;