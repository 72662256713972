import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
import Logos from "../Components/Logos";
class Products extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Products</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="#"><i className="fa fa-home"></i> Home</a></li>
                        <li>Products</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Our <span className="txtred">Products</span></h2>
                        <p className="greensmall">Empowering Wellness, Every Day with Our Products.</p>
                        <p align="jusify">Reliwand Pharmaceuticals Pvt Ltd differentiates itself in the pharmaceutical industry for a number of products.</p>
                    </header> 

                    
                </div> 

                <div className="col-12 col-lg-6 align-content-lg-stretch">
                        <img src="../images/product1.png" style={{width:'100%' }}/>
                        <h3>PTH Pride</h3>
                        <p>PTH PRIDE  Injection is a man-made version of parathyroid hormone used to treat men and postmenopausal women who have severe osteoporosis with a high risk of fractures. </p>
                        <div class="entry-content ezuca-stats"> <NavLink to="/pth-pride" className="btn mt-4 mt-sm-0" >Read More</NavLink>
                           </div>
                </div>
                <div className="col-12 col-lg-6 align-content-lg-stretch">
                <img src="../images/product2.png"  style={{width:'100%' }}/>
                        <h3>Synovisc</h3>
                        <p>Synovisc Injection is a medicine used in the treatment of osteoarthritis. It decreases the pain and swelling in the joints. It may be used alone or in combination with other medicines.</p>
                        <div class="entry-content ezuca-stats"><NavLink to="/synovisc" className="btn mt-4 mt-sm-0" >Read More</NavLink></div>
                </div>
            </div> 
        </div>
    </section>
   <Logos/> 

     </>    
        );
    }
}

export default Products;