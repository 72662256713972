import React,{Component} from "react";
import Logo  from '../logo.jpg';
import { NavLink, Link } from "react-router-dom";
import Menu from "../Components/Menu";
import Logos from "../Components/Logos";
class Career extends Component
{
    render(){
        
        return ( 
   <>
            <div className="page-header">
        <header className="site-header">
            <Menu/>
        </header> 

        <div className="page-header-overlay">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <header className="entry-header">
                            <h1>Career</h1>
                        </header> 
                    </div> 
                </div> 
            </div> 
        </div> 
    </div>
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="breadcrumbs">
                    <ul className="flex flex-wrap align-items-center p-0 m-0">
                        <li><a href="#"><i className="fa fa-home"></i> Home</a></li>
                        <li>Career</li>
                    </ul>
                </div> 
            </div> 
        </div> 
 
    </div>
    <section className="about-section">
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-12 align-content-lg-stretch">
                    <header className="heading">
                        <h2 className="entry-title">Work <span className="txtred">With</span> Us</h2>
                         
                        <p align="jusify">We are committed to hiring exceptionally talented people and nurture them professionally. Our multi-dimensional work environment offers high growth opportunities through challenging roles with clear responsibilities and the opportunity to work on a variety of assignments. At Reliwand, our employees are provided with opportunities to enhance their technical and soft skills through continuous training and development programmes.</p>
                        <p>Candidates who are willing to work with us can share their reesume at <Link to='javascript:void(0)'
      onClick={() => window.location = 'mailto:career@reliwand.com'}>
  career@reliwand.com
</Link></p>
                    </header> 

                    
                </div> 

                
            </div> 
        </div>
    </section>
   <Logos/> 

     </>    
        );
    }
}

export default Career;